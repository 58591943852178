import React from 'react'
export const minus = {
  path: (
    <rect
      x="14"
      y="9"
      width="2"
      height="8"
      rx="1"
      transform="rotate(90 14 9)"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
}
