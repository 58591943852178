import React from 'react'
export const sparks = {
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.35908 0.687925C3.38724 0.575705 3.6129 0.575705 3.64107 0.687925C3.78593 1.26502 4.05367 2.06363 4.49497 2.50493C4.93627 2.94623 5.73488 3.21397 6.31198 3.35883C6.4242 3.387 6.4242 3.61266 6.31198 3.64083C5.73488 3.78569 4.93627 4.05343 4.49497 4.49473C4.05367 4.93603 3.78593 5.73463 3.64107 6.31173C3.6129 6.42395 3.38724 6.42395 3.35908 6.31173C3.21422 5.73463 2.94648 4.93603 2.50517 4.49473C2.06387 4.05343 1.26527 3.78569 0.68817 3.64083C0.575949 3.61266 0.575949 3.387 0.688169 3.35883C1.26527 3.21397 2.06387 2.94623 2.50517 2.50493C2.94648 2.06363 3.21422 1.26502 3.35908 0.687925ZM4.85418 10.6866C4.88399 10.5749 5.11627 10.5749 5.14607 10.6866C5.27507 11.1705 5.4988 11.793 5.8529 12.1471C6.207 12.5012 6.82949 12.7249 7.31336 12.8539C7.42516 12.8837 7.42516 13.116 7.31336 13.1458C6.82949 13.2748 6.207 13.4986 5.8529 13.8527C5.4988 14.2068 5.27507 14.8292 5.14607 15.3131C5.11627 15.4249 4.88399 15.4249 4.85418 15.3131C4.72519 14.8292 4.50146 14.2068 4.14736 13.8527C3.79325 13.4986 3.17076 13.2748 2.68689 13.1458C2.57509 13.116 2.57509 12.8837 2.68689 12.8539C3.17076 12.7249 3.79325 12.5012 4.14736 12.1471C4.50146 11.793 4.72519 11.1705 4.85418 10.6866ZM11.1318 3.68912C11.1065 3.57621 10.8938 3.57621 10.8685 3.68911C10.6838 4.51296 10.2884 5.86906 9.57884 6.5786C8.86931 7.28813 7.51321 7.6836 6.68936 7.86821C6.57646 7.89351 6.57646 8.10626 6.68936 8.13156C7.51321 8.31617 8.86931 8.71163 9.57884 9.42117C10.2884 10.1307 10.6838 11.4868 10.8685 12.3106C10.8938 12.4236 11.1065 12.4236 11.1318 12.3107C11.3164 11.4868 11.7119 10.1307 12.4214 9.42117C13.1309 8.71163 14.487 8.31617 15.3109 8.13156C15.4238 8.10626 15.4238 7.89351 15.3109 7.86821C14.487 7.6836 13.1309 7.28813 12.4214 6.5786C11.7119 5.86906 11.3164 4.51296 11.1318 3.68912Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 16 16',
}
