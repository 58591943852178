import React from 'react'
export const refresh = {
  path: (
    <>
      <path
        d="M14.4027 10.8298C15.8352 7.6001 14.6285 3.74335 11.4999 1.93704C10.0464 1.09788 8.41707 0.839208 6.88035 1.0891L7.40171 3.03483C8.43934 2.909 9.52498 3.10624 10.4999 3.66909C12.6706 4.92235 13.546 7.55749 12.6566 9.82176L11.321 9.05066L11.696 13.5973L15.821 11.6487L14.4027 10.8298Z"
        fill="currentColor"
        key="jaVhmpPZ9"
      />
      <path
        d="M4.49986 14.0614C1.37124 12.2551 0.164503 8.39833 1.59707 5.16859L0.178711 4.3497L4.30371 2.40114L4.67871 6.94777L3.34313 6.17667C2.45369 8.44094 3.32915 11.0761 5.49986 12.3293C6.47475 12.8922 7.56039 13.0894 8.59802 12.9636L9.11937 14.9093C7.58266 15.1592 5.95333 14.9006 4.49986 14.0614Z"
        fill="currentColor"
        key="oZeA3P5dEW"
      />
    </>
  ),
  viewBox: '0 0 16 15',
}
