import React from 'react'
export const plus = {
  path: (
    <>
      <rect x="9" y="6" width="2" height="8" rx="1" fill="currentColor" key="8U8BfvfSU" />
      <rect
        x="14"
        y="9"
        width="2"
        height="8"
        rx="1"
        transform="rotate(90 14 9)"
        fill="currentColor"
        key="HmmyTJhj6U"
      />
    </>
  ),
  viewBox: '0 0 20 20',
}
