import React from 'react'
export const question_o = {
  path: (
    <g key="1f7yFPU5D">
      <path
        d="M12.8827 8.28568C12.8827 8.64368 12.8346 8.95582 12.6694 9.24956C12.5133 9.53413 12.215 9.83246 11.7744 10.1446L11.3475 10.4613C11.0905 10.6448 10.8977 10.8284 10.7692 11.012C10.738 11.06 10.712 11.1158 10.6904 11.176C10.587 11.4644 10.3553 11.7142 10.0489 11.7142H9.64804C9.32152 11.7142 9.06058 11.4371 9.1634 11.1272C9.22633 10.9376 9.31056 10.7582 9.40607 10.6127C9.59884 10.3098 9.87881 10.0298 10.246 9.77279C10.6407 9.49741 10.9207 9.25415 11.0859 9.04303C11.2511 8.8319 11.3338 8.59782 11.3338 8.3408C11.3338 8.01034 11.2282 7.75331 11.0171 7.56973C10.8059 7.37696 10.503 7.28057 10.1083 7.28057C9.73194 7.28057 9.41525 7.39073 9.15822 7.61103C8.9572 7.78334 8.82355 7.99777 8.75729 8.25431L7.23633 7.87407C7.39134 7.37242 7.6648 6.96346 8.05669 6.64719C8.57992 6.22494 9.19947 6 10.0256 6C10.9068 6 11.655 6.21576 12.1599 6.61965C12.6648 7.01437 12.8827 7.57886 12.8827 8.28568Z"
        fill="currentColor"
        key="_Z-BXtwoN"
      />
      <path
        d="M9.61949 13.9999C9.3039 13.9999 9.04807 13.7441 9.04807 13.4285V13.0705C9.04807 12.7549 9.3039 12.4991 9.61949 12.4991H10.0876C10.4032 12.4991 10.6591 12.7549 10.6591 13.0705V13.4285C10.6591 13.7441 10.4032 13.9999 10.0876 13.9999H9.61949Z"
        fill="currentColor"
        key="7VU4imphCG"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10Z"
        fill="currentColor"
        key="Lt2uWPOssc"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
}
